import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
// components
import Logo from 'components/logo';
import Scrollbar from 'components/scrollBar';
import NavSection from 'components/navSection';
import { MHidden } from 'components/@material-extend';
//
import adminSidebar from './AdminSidebar';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const DashboardSidebar = ({
  isOpenSidebar, 
  onCloseSidebar,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex', alignItems: "center", textDecoration: "none", color: "inherit" }}>
          <Logo />
          <span style={{marginLeft: "15px", fontSize: "18px"}}>Andara88</span>
        </Box>
      </Box>

      <NavSection navConfig={adminSidebar} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool, 
  onCloseSidebar: PropTypes.func,
  onChangeApp: PropTypes.func,
};

DashboardSidebar.defaultProps = {
  isOpenSidebar: false,
  onCloseSidebar: () => {},
  onChangeApp: () => {},
};


export default DashboardSidebar;